










































































































import Vue, { PropOptions } from 'vue'

import CCustomSelectDropdownNoSearchResults from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdownNoSearchResults.vue'
import { DropdownDirection } from '~/components/shared/configurable/form/select/types'
import CCustomSelectDropdownControls from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdownControls.vue'
import { IndexedOption } from '~/models/shared/types'
import CCustomSelectDropdownFooter from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdownFooter.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import ScrollService from '~/services/scroll/ScrollService'
export default Vue.extend({
  components: {
    CCustomSelectDropdownFooter,
    CCustomSelectDropdownNoSearchResults,
    CCustomSelectDropdownControls
  },
  props: {
    q: {
      type: String,
      required: true
    },
    showAllButton: {
      type: Boolean,
      required: true
    },
    searchPlaceholder: {
      type: String,
      required: true
    },
    filteredOptions: {
      type: Array,
      required: true
    } as PropOptions<IndexedOption[]>,
    dropdownClass: {
      type: String,
      required: true
    },
    noResultsMessage: {
      type: String,
      required: false,
      default: ''
    },
    searchable: {
      type: Boolean,
      required: true
    },
    selectedOptions: {
      type: Array,
      required: true
    } as PropOptions<IndexedOption[]>,
    multiSelect: {
      type: Boolean,
      required: true
    },
    dropdownDirection: {
      type: String,
      required: false,
      default: DropdownDirection.Below
    },
    internalValue: {
      type: [Array, Number, String],
      default: null
    },
    allAreSelected: {
      type: Boolean,
      required: true
    },
    showDropdownFooter: {
      type: Boolean,
      required: false
    },
    checkboxDividerIndices: {
      type: Array,
      required: false,
      default() {
        return []
      }
    } as PropOptions<number[]>,
    hiddenSingleSelectDivider: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    focusedOptionValue: null,
    debouncedFilteredOptions: null
  }),
  computed: {
    ...mapDeps({
      scrollService: ScrollService
    }),
    showOptions() {
      return this.debouncedFilteredOptions !== null
    }
  },
  watch: {
    filteredOptions(newFilteredOptions) {
      this.debouncedFilteredOptions = newFilteredOptions
    },
    showOptions(val, oldVal) {
      if (val && !oldVal && this.selectedOptions.length === 1) {
        // from false to true
        this.scrollToSelectedOptions()
      }
    }
  },
  async mounted() {
    await new Promise(resolve => {
      if (this.filteredOptions.length > 45) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.debouncedFilteredOptions = this.filteredOptions
            this.$nextTick(() => resolve())
          })
        })
      } else {
        this.debouncedFilteredOptions = this.filteredOptions
        this.$nextTick(() => resolve())
      }
    })
  },
  methods: {
    optionInSelected(option) {
      return this.selectedOptions.some(
        selectedOption => selectedOption.value === option.value
      )
    },
    async scrollToSelectedOptions() {
      if (!this.multiSelect) {
        try {
          await this.$nextTick()
          const selectedElement = document.getElementsByClassName(
            'option selected'
          )[0]
          if (selectedElement && this.$refs.optionsList) {
            const selectedElementIsOverflown =
              selectedElement.offsetTop > this.$refs.optionsList.clientHeight
            if (selectedElementIsOverflown) {
              this.scrollService.scrollTo(selectedElement, {
                container: this.$refs.optionsList,
                duration: 1
              })
            }
          }
        } catch (error) {
          this.$logger.captureError(new Error('Could not scroll to selected'))
        }
      }
    },
    handleMouseLeaveOptions() {
      this.focusedOptionValue = null
    },
    handleSelect(option) {
      if (this.canDeselect && this.optionInSelected(option)) {
        this.$emit('select', null)
      } else {
        this.$emit('select', option.value)
      }
    },
    handleFocus(value) {
      this.focusedOptionValue = value
    }
  }
})
